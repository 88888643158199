<template>
    <div class="foot-bar">
      <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "foot-bar"
    }
</script>

<style lang="less" scoped>
.foot-bar{
  position: fixed;
  width: 100%;
  padding: 12px 28px;
  left: 182px;
  bottom: 0;
  background: #ffffff;
  /*height: 56px;*/
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, .6), 0 2px 4px 0 rgba(232, 237, 250, .5);
}
</style>
