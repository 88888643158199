<template>
  <div>
    <!-- 手机号 -->
    <!-- 明文 -->
    <span v-if="maskType === 1">
      <!-- 请求接口的过程中先展示密文，返回成功后才显示明文，不然请求接口过程中会闪一下 -->
      <span v-if="clearData.length > 0">{{ clearData }}</span>
      <span v-else>{{ maskData }}</span>
    </span>
    <!-- 密文 -->
    <span v-else>{{ maskData }}</span>
    <!-- 查看按钮-有对应UI权限才显示 -->
    <span v-if="hasUIPermission && maskData !== ''" @click="toggleEypes"
          style="margin-left: 8px; cursor: pointer; color:#ff9505"
          :class="['iconfont', 'check-icon','buttonIconText', maskType === 1 ? 'Business-PC-Eyes' : 'Hide' ]" />
  </div>
</template>

<script type="text/ecmascript-6">
import { system } from '@/controller/api';

export default {
  name: 'MaskData',
  props: {
    maskData: { // 手机号-暗文
      type: String,
      default() {
        return '';
      },
    },
    paramType: { // 本条手机号所属的数据-类型
      type: String,
      default() {
        return '';
      },
    },
    param: { // 查看本条手机号明文所需的参数
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      maskType: 0, // 当前手机号的展示形式 0：展示掩码 1：展示明文
      clearData: '', // 手机号-明文
    };
  },
  computed: {
    hasUIPermission() { // 获取是否存在查看明文手机号的权限
      // const uiPermissionCodes = localStorage.getItem('uiPermissionCodes');
      // const permissionCodeList = (uiPermissionCodes && uiPermissionCodes.split(',')) || [];
      // return permissionCodeList.includes('el-group-ui-unionplaintext');
      return true; // todo 增加权限判断
    },
  },
  methods: {
    // 切换小眼睛查看or掩码
    toggleEypes() {
      this.maskType = this.maskType === 1 ? 0 : 1;
      // 如果当前状态为查看明文，则调用接口获取
      if (this.maskType === 1) {
        this.getClearText();
      }
    },
    // 获取明文手机号
    getClearText() {
      this.$httpUtil.fetch({
        url: system.getClearText,
        data: {
          paramType: this.paramType,
          param: JSON.stringify(this.param),
        },
      }).then((res) => {
        if (res.retcode === 0) {
          this.clearData = (res.body && res.body.clearText) || '';
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.buttonIconText{
    padding: 4px;
    &:hover{
      background: #ECEFFF;
      border-radius: 2px;
    }
  }
</style>
