<template>
  <div class="listPageInfoComm" :class="{'no-border-list': isNoBorder}">
    <div class="searchBlock" ref="searchBlockRef">
      <el-card shadow="never">
        <div class="lc-search-form-collapse">
          <slot name="search" />
        </div>
      </el-card>
    </div>
    <div class="tableBlock">
      <slot name="table" />
    </div>
    <div class="paginationBlock">
      <slot name="page" />
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListTemplate',
  props: {
    isNoBorder: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.no-border-list{
    /deep/ .searchBlock {
        padding: 0;
    }
    /deep/ .tableBlock{
        padding: 8px 0 0 0;
        border: 0;
        .el-table th {
            border-right: 0;
        }
        .el-table th.is-leaf{
            border-top: 1px solid #ebeef5;
        }
    }
    /deep/ .paginationBlock{
        border: 0;
        .el-pagination ul li{
            width: unset;
            min-width: 32px;
        }
    }
}
/*/deep/.searchBlock .date-pre{*/
/*    width: auto;*/
/*    padding: 0 8px;*/
/*}*/
/*/deep/ .paginationBlock{*/
/*    .el-pagination ul li{*/
/*        width: unset;*/
/*        min-width: 32px;*/
/*    }*/
/*}*/
/deep/.searchBlock .date-pre {
  display: inline-block;
  width: auto;
  height: 30px;
  padding: 0 8px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #d8dee8;
  border-right: 0;
  font-size: 14px;
}
/deep/.searchBlock .el-date-editor--daterange.el-input__inner {
  width: 260px !important;
}
</style>
