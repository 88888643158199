const prefix = '/api/merchant/alliance';
const memberPrefix = `${prefix}/member`;
const systemPrefix = `${memberPrefix}/system`; // 会员体系-前缀
const rankPrefix = `${prefix}/member/rank`; // 会员等级-前缀
const travelPrefix = `/business/travel/member`;
const salesPromotionPrefix = `${prefix}/salesPromotion`;
const pointPrefix = '/api/alliance/member';
// 会员体系
export const system = {
  queryAllianceMemberSystemById: `${systemPrefix}/queryAllianceMemberSystemById`, // 获取平台会员体系信息
  saveAllianceMemberSystem: `${systemPrefix}/saveAllianceMemberSystem`, // 保存会员体系信息
  updateAllianceMemberSystemAgreements: `${systemPrefix}/agreement/updateAllianceMemberSystemAgreements`, // 新增联盟平台会员体系会员协议
  queryAllianceMemberSystemAgreements: `${systemPrefix}/agreement/queryAllianceMemberSystemAgreements`, // 查询联盟平台会员体系会员协议
  queryAllianceGroupChannelByGroupId: `${systemPrefix}/queryAllianceGroupChannelByGroupId`, // 获取联盟平台渠道
  queryGradingPolicyDataAdaptationRule: `${rankPrefix}/queryGradingPolicyDataAdaptationRule`, // 查询升降级策略
  saveGradingPolicyDataAdaptationRule: `${rankPrefix}/saveGradingPolicyDataAdaptationRule`, // 保存升降级策略
  queryAllianceMemberRankList: `${rankPrefix}/queryAllianceMemberRankList`, // 获取平台会员等级
  queryAllianceMemberRankContainPolicyCondition: `${rankPrefix}/queryAllianceMemberRankContainPolicyCondition`, // 查询等级详情
  updateAllianceMemberRankContainPolicyCondition: `${rankPrefix}/updateAllianceMemberRankContainPolicyCondition`, // 更新等级信息和策略信息
};

export const member = {
  queryAllianceMemberByPage: `${memberPrefix}/queryAllianceMemberByPage`, // 获取会员列表
  queryAllianceMember: `${memberPrefix}/queryAllianceMember`, // 获取会员详情
  updateMemberInfo: `${memberPrefix}/updateMemberInfo`, // 编辑会员详情
  getCancelMemberText: `${memberPrefix}/getCancelAllianceMemberText`, // 获取注销提示文案
  cancelMember: `${memberPrefix}/cancelMember`, // 注销会员
  changeBindingPhoneNumSmsSend: `${memberPrefix}/changeBindingPhoneNumSmsSend`, // 换绑手机号发送验证码
  changeBindingPhoneNum: `${memberPrefix}/changeBindingPhoneNum`, // 换绑手机号
  unbind: `${memberPrefix}/unbind`, // 会员解绑
  manualUpgradeAllianceMemberLevel: `${memberPrefix}/manualUpgradeAllianceMemberLevel`, // 会员手动升降级
  queryAllianceMemberSumData: `${memberPrefix}/queryAllianceMemberSumData`, // 会员信息汇总
  supplyPoint: `${pointPrefix}/points/supplyPoint`, // 补录积分
  supplyNights: `${memberPrefix}/nights/supplyNights`, // 补录晚数
  sendSupplyPointRoomNightSMS: `${memberPrefix}/sendSupplyPointRoomNightSMS`, // 积分\间夜补录B端发送短信验证码 todo  后端暂未提供
  queryAllianceMemberRankChangeDetail: `${rankPrefix}/queryAllianceMemberRankChangeLogList`, // 查询会员等级变更记录
  getAllianceMemberPointList: `${pointPrefix}/points/getAllianceMemberPointList`, // 积分流水
  getPointsPolicyList: `${pointPrefix}/points/policy/getPointsPolicyList`, // 积分策略列表
  updatePointsPolicyStatus: `${pointPrefix}/points/policy/updatePointsPolicyStatus`, // 积分策略列表
  getPointsPolicyDetail: `${pointPrefix}/points/policy/getPointsPolicyDetail`, // 积分策略详情
  savePointsPolicy: `${pointPrefix}/points/policy/savePointsPolicy`, // 保存积分策略
  savePointsUsedRule: `${pointPrefix}/points/used/savePointsUsedRule`, // 保存积分使用规则
  getPointsUsedRuleDetail: `${pointPrefix}/points/used/getPointsUsedRuleDetail`, // 获取积分使用规则
  queryAllianceMemberNightsDetail: `${memberPrefix}/nights/queryAllianceMemberNightsList`, // 间夜明细
  queryRightDic: `${memberPrefix}/right/queryRightDic`, // 获取权益字典列表
  updateRightDicStatus: `${memberPrefix}/right/updateRightDicStatus`, // 更新权益字典状态
  updateRightDic: `${memberPrefix}/right/updateRightDic`, // 更新权益字典信息
  saveAllianceMemberLevelRoomDiscount: `${memberPrefix}/right/saveAllianceMemberLevelRoomDiscount`, // 保存房价折扣
  queryAllianceMemberLevelRoomDiscount: `${memberPrefix}/right/queryAllianceMemberLevelRoomDiscount`, // 获取房价折扣
  saveAllianceMemberRight: `${memberPrefix}/right/saveAllianceMemberRight`, // 保存等级权益
  queryAllianceMemberRightDetail: `${memberPrefix}/right/queryAllianceMemberRightDetail`, // 获取等级权益
  getAllianceMemberCouponList: `${memberPrefix}/coupon/getAllianceMemberCouponList`, // 会员的券列表
  invalidAllianceMemberCoupon: `${memberPrefix}/coupon/invalidAllianceMemberCoupon`, // 失效优惠券
  queryPresentedRankChangeDetail: `${rankPrefix}/queryPresentedRankChangeDetail`, // 获取表现等级级别变更明细
};
export const salesPromotion = {
  queryCouponSalesPromotionIdAndName: `${salesPromotionPrefix}/queryCouponSalesPromotionIdAndName`, // 查询优惠券
}

export const travel = {
  queryBusinessTravelMemberRankList: `${travelPrefix}/rank/queryBusinessTravelMemberRankList`,
  updateBusinessTravelMemberRank: `${travelPrefix}/rank/updateBusinessTravelMemberRank`,
  manualUpgrade: `${travelPrefix}/rank/changeBusinessTravelCompanyRank/manualUpgrade`,
  manualDowngrade: `${travelPrefix}/rank/changeBusinessTravelCompanyRank/manualDowngrade`,
  queryBusinessTravelMemberRankRightDic: `${travelPrefix}/rank/right/queryBusinessTravelMemberRankRightDic`,
  getBusinessTravelMemberRankRight: `${travelPrefix}/rank/right/getBusinessTravelMemberRankRight`,
  saveBusinessTravelMemberRankRight: `${travelPrefix}/rank/right/saveBusinessTravelMemberRankRight`,
};

export const marketing = {
  getBrandList: `/api/merchant/product/hotel/getBrandList`,
  getUnselectedHotelListForActivityCoupon: `/api/merchant/product/activity/coupon/getUnselectedHotelListForActivityCoupon`,
  getHotelRoomTypeForActivityCoupon: `/api/merchant/product/activity/coupon/getHotelRoomTypeForActivityCoupon`,
  queryCouponSalesPromotionListByPage: `/api/merchant/alliance/salesPromotion/queryCouponSalesPromotionListByPage`,
  createCouponSalesPromotion: `/api/merchant/alliance/salesPromotion/createCouponSalesPromotion`,
  editCouponSalesPromotion: `/api/merchant/alliance/salesPromotion/editCouponSalesPromotion`,
  updateCouponSalesPromotionStatus: `/api/merchant/alliance/salesPromotion/updateCouponSalesPromotionStatus`,
};
