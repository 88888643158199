const groupRouter = {
  path: '/alliance',
  component: () => import(/* webpackChunkName: "alliance-view-index" */ '../views/index.vue'),
  children: [
    // 会员体系-基础配置
    {
      path: 'basicSetting',
      name: 'basicSetting',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/basic-setting.vue'),
    },
    // 会员体系-升降级配置
    {
      path: 'levelUpgradeSetting',
      name: 'levelUpgradeSetting',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/level-upgrade-setting.vue'),
    },
    // 会员体系-协议
    {
      path: 'agreements',
      name: 'agreements',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/agreements.vue'),
    },
    // 小程序配置-展示配置
    {
      path: 'displaySetting',
      name: 'displaySetting',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/display-setting.vue'),
    },
    // 会员等级
    {
      path: 'levelList',
      name: 'levelList',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/level-list.vue'),
    },
    // 会员等级详情
    {
      path: 'levelInfo',
      name: 'levelInfo',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/edit-level-info.vue'),
    },
    // 会员列表
    {
      path: 'memberList',
      name: 'memberList',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/member-list.vue'),
    },
    // 会员详情
    {
      path: 'memberDetail',
      name: 'memberDetail',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/member-detail.vue'),
    },
    // 积分间夜明细
    {
      path: 'integralDetail',
      name: 'integralDetail',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/integral-detail.vue'),
    },
    // 企业等级
    {
      path: 'enterpriseLevel',
      name: 'enterpriseLevel',
      meta: {
        permissionCode: 'cp-group-enterpriseMember',
        routerType: 2,
      },
      component: () => import('../views/enterprise-manage/enterprise-level.vue'),
    },
    // 企业等级
    {
      path: 'enterpriseRightInfo',
      name: 'enterpriseRightInfo',
      meta: {
        permissionCode: 'cp-group-enterpriseMember',
        routerType: 2,
      },
      component: () => import('../views/enterprise-manage/right-info.vue'),
    },
    // 权益列表
    {
      path: 'rightsList',
      name: 'rightsList',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/rights-list.vue'),
    },
    // 编辑等级权益
    {
      path: 'levelRightsInfo',
      name: 'levelRightsInfo',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/edit-level-rights.vue'),
    },
    {
      path: 'pointGiven',
      name: 'pointGiven',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/point/given.vue'),
    },
    {
      path: 'pointSet',
      name: 'pointSet',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/point/set.vue'),
    },
    {
      path: 'pointUse',
      name: 'pointUse',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/point/use.vue'),
    },
    {
      path: 'memberCouponList',
      name: 'memberCouponList',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/system/member-coupon-list.vue'),
    },
    {
      path: 'marketing/couponList',
      name: 'couponList',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/marketing/coupon-list.vue'),
    },
    {
      path: 'marketing/couponCreate',
      name: 'couponCreate',
      meta: {
        permissionCode: 'cp-alliance-setting',
        routerType: 2,
      },
      component: () => import('../views/marketing/coupon-create.vue'),
    }
  ],
};

export default groupRouter;
