/*
 * @Descripttion:
 * @version:
 * @Author: wangxiaole
 * @Date: 2020-02-28 15:37:26
 * @LastEditors: wangxiaole
 * @LastEditTime: 2020-02-28 15:40:48
 */

import Router from 'vue-router';
import groupRouter from './groupRouter';
const routes = [
  groupRouter
];

export default new Router({
  mode: 'history',
  routes,
});
