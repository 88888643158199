const pathMap = {
  lcproduct:{
    storageKey: 'VUE_NAVIGATION_LCPRODUCT',
    routes:[]
  },
  marketing:{
    storageKey: 'VUE_NAVIGATION_MARKETING',
    routes:[]
  },
  alliance:{
    storageKey: 'VUE_NAVIGATION_ALLIANCE',
    routes:[]
  },
}

export default class CurInfo{
  static CUR_STORAGE_KEY;
  static Routes;
  constructor(moduleName) {
    CurInfo.CUR_STORAGE_KEY = pathMap[moduleName].storageKey || ''
    CurInfo.Routes = []
    if(window.sessionStorage[CurInfo.CUR_STORAGE_KEY]){
      CurInfo.Routes = JSON.parse(window.sessionStorage[CurInfo.CUR_STORAGE_KEY])
    }
  }
}
