import { render, staticRenderFns } from "./foot-bar.vue?vue&type=template&id=6f9f6be1&scoped=true&"
import script from "./foot-bar.vue?vue&type=script&lang=js&"
export * from "./foot-bar.vue?vue&type=script&lang=js&"
import style0 from "./foot-bar.vue?vue&type=style&index=0&id=6f9f6be1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f9f6be1",
  null
  
)

export default component.exports